import { signal } from "@preact/signals-react";
import axios from "axios";
const initialGPSList = [{longitude: 0, latitude: 0, time: 0}];
export var geoState_gpsList = signal([]);
export var geoState_gpsListDataSource = signal([]);

const baseUrl = process.env.REACT_APP_GEO + '/geo';

export async function getGPSData(device_name) {
    try {
        const response = await axios.post(baseUrl + '/getLastDeviceGPS', {
            device_name: device_name
        });
        geoState_gpsList = response.data;
        createDataSource();
    } catch (error) {
        console.error(error);
        geoState_gpsList = [initialGPSList];
    }
}

// create a data source for map from geoState_gpsList
function createDataSource() {
    const dataSource = [];
    geoState_gpsList.forEach((gps) => {
        dataSource.push({
            type : "Feature",
            geometry : {
                type : "Point",
                coordinates : [gps.longitude, gps.latitude]
            },
            properties : {
                time : gps.timestamp,
                name : gps._id
            }
        });
    });
    geoState_gpsListDataSource = dataSource;
}
