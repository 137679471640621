import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getGPSData, geoState_gpsList,geoState_gpsListDataSource } from './geoState'; // import the function
import { effect } from '@preact/signals-react';

const token = process.env.REACT_APP_MAPBOX_TOKEN;


const styles = {
  width: '100vw',
  height: 'calc(100vh - 80px)',
  position: 'absolute'
};

const MapPoint = () => {
  
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  console.log("token: ", token)
  
  useEffect(() => {
    console.log('useEffect');
    getGPSData('GPS_TEST');
  }, []);
  useEffect(() => {
    let lat = 0;
    let lng = 0;
    try {
      lat = geoState_gpsList[0].latitude;
      lng = geoState_gpsList[0].longitude;
      console.log('lat', lat);
      console.log('lng', lng);
      map.setCenter([lng, lat]);
    } catch (e) {
      console.log('error');
    }
  }, [geoState_gpsList]);
  useEffect(() => {
    console.log('useEffect2');
    let lat = 0;
    let lng = 0;

    mapboxgl.accessToken = token;
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v12', // stylesheet location
        center: [lng, lat],
        zoom: 13
      });

      map.on('load', () => {
        setMap(map);
        map.resize();

        map.loadImage(
          'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
          (error, image) => {
            if (error) throw error;
            map.addImage('custom-marker', image);
            
            map.addSource('points', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: geoState_gpsListDataSource
              }
            });

            // Add a symbol layer
            map.addLayer({
              id: 'points',
              type: 'symbol',
              source: 'points',
              layout: {
                'icon-image': 'custom-marker',
                // get the title name from the source's "title" property
                'text-field': ['get', 'name'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1.25],
                'text-anchor': 'top'
              }
            });
          }
        );
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });

    // call the function with the device name
    console.log(geoState_gpsList);
  }, [map]);

  return <div ref={el => (mapContainer.current = el)} style={styles} />;
};

export default MapPoint;
