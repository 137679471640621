import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";

import { Modal } from "react-bootstrap";

import Login from "../user/Login";
import RegisterUser from "../user/registerUser";
import ChangePassword from "../user/password";


import { closeModal } from "./modalSlice";


const ModalForm = () => {
  const { isModalOpen, headerText, modalComponentName } = useSelector(
    (store) => store.modal
  );
  const dispatch = useDispatch();
  //let navigate = useNavigate();
  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    //if (currentUser.isLoggedIn){
    //  navigate("/")
    // }
  });
  return (
    <Modal show={isModalOpen} onHide={handleClose}>
      <Modal.Header closeButton  className='text-white'>
      <Modal.Title className="text-center">{headerText}</Modal.Title>

      </Modal.Header>
      {modalComponentName === "Login" ? (
        <Modal.Body>
          <Login />
        </Modal.Body>
      ) : (
        ""
      )}

      {modalComponentName === "registerUser" ? (
        <Modal.Body className="text-center">
          <RegisterUser />
        </Modal.Body>
      ) : (
        ""
      )}

      {modalComponentName === "changePassword" ? (
        <Modal.Body>
          <ChangePassword />
        </Modal.Body>
      ) : (
        ""
      )}
      
    </Modal>
  );
};

export default ModalForm;
