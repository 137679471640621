import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, } from 'react-bootstrap';


import Header from "../../Header";
import Footer from "../../footer";

import { useMediaQuery } from '@material-ui/core';
import '../../App.css';
import MapPoint from '../geo/mappoint';

//Components
const Home = () => {
  const dispatch = useDispatch();
 

  const isMobile = useMediaQuery('(max-width: 599px)');
  const isDesktop = useMediaQuery('(min-width: 600px)');

  return (
   <>
   <Header/>
    <section class='main-layout'>
      <MapPoint/>
      
        
      
      
    </section>
    <Footer/>
    </>
   
  ); 
};

export default Home;
